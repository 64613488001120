import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import { ThemeContext } from '../layouts';
import Hero from '../components/Hero';
import Seo from '../components/Seo';
import { Col, Row } from 'antd/lib/grid';
import { Button, Avatar, Card } from 'antd';
import Submission from '../components/Submission';

import 'antd/lib/grid/style/index.css';

const { Meta } = Card;

class IndexPage extends React.Component {
    separator = React.createRef();

    scrollToContent = (e) => {
        this.separator.current.scrollIntoView({
            block    : 'start',
            behavior : 'smooth'
        });
    };

    render() {
        const message = {
            text: "SMART TOOLS & SUPPORT TO HACK YOUR WAY BACK TO HEALTH"
        }
        
        const {
            data : {
                section5Image : { resize: { src: section5ImgBg } },
                sectionImage  : { resize: { src: sectionImgBg } },
                section2Image : {
                    resize : { src: section2ImgBg } // the new image with filename section2-bakckground.png
                },
                image1        : { resize: { src: testimonyPersonImg } },
                bgDesktop     : { resize: { src: desktop } },
                bgTablet      : { resize: { src: tablet } },
                bgMobile      : { resize: { src: mobile } },
                site          : { siteMetadata: { facebook } }
            }
        } = this.props;

        const backgrounds = {
            desktop,
            tablet,
            mobile
        };

        return (
            <React.Fragment>
                <ThemeContext.Consumer>
                    {(theme) => (
                        <Hero
                            scrollToContent={this.scrollToContent}
                            backgrounds={backgrounds}
                            theme={theme}
                            message={message}
                        />
                    )}
                </ThemeContext.Consumer>
                <hr ref={this.separator} />
                <div id="section1" className="section section1">
                    <Row >
                        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 8 }}>
                            <div className="separator " />
                            <h2>
                                COMMONALITY.ME GIVES SUFFERERS OF CHRONIC
                                ILLNESS THE TOOLS & SUPPORT TO HACK THEIR WAY
                                BACK TO HEALTH.
                            </h2>
                            <div className="narrative">
                                <p>
                                    Has the medical establishment run out
                                   of ideas to help you? Commonality.me is for
                                    you.
                                </p>
                                <p>
                                    No-one knows your body better than you do.
                                    We give you the smart tools to become your
                                    own body's Chief Scientist. We call it{' '}
                                    <i>BodyHacking</i> and it moves you from
                                    being the recipient of a label, to an active
                                    explorer of solutions.{' '}
                                </p>
                                <p>
                                    If you suffer from chronic illness, you are
                                    a HERO! We want to give you the smart tools
                                    (backed with the latest machine learning)
                                    for some super powers to hack your way back
                                    to health. 
                                </p>
                                <p>Ready?</p>
                                <br />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div id="section2" className="section section2">
                    <Row>
                        <Col xs={{ span: 4}} lg={{ span:16 }}>
                            <div className="section2-img-bg" />
                        </Col>
                        <Col xs={{ span: 16}} lg={{ span:8 }}>
                            <div className="section2-content">
                                <div className="separator " />
                                <h2>CHRIS' STORY</h2>
                                <h3>12 YEARS SUFFERED CHRONIC ILLNESS</h3>
                                <div className="narrative">
                                    <p>
                                        Chris suffered from chronic illness for
                                        twelve years. Twelve years of pain,
                                        suffering and life passing him by.
                                    </p>
                                    <p>
                                        But after trying countless doctors,
                                        naturopaths and diets, it was only by
                                        using machine learning that he
                                        discovered a readily available
                                        nutritional component that was they key
                                        to recovering his health.
                                    </p>
                                    <p>
                                        Chris became his own body hacker, his
                                        own Chief Scientist, and was eventually
                                        healed. We give sufferers the tools to
                                        do what Chris did and become their own
                                        body hacker.
                                    </p>
                                    <div className="separator" style={{ marginTop: 40, marginBottom: 40 }}/>
                                    <Button href="/story">READ MORE</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div id="section5" className="section">
                    <Row>
                        <Col xs={{ span: 8, offset: 0 }} lg={{ span: 8, offset: 3 }}>
                            <div className="section5" />
                        </Col>
                        <Col xs={{ span: 12, offset: 2 }} lg={{ span: 8, offset: 3 }}>
                            <div className="separator" />
                            <div className="content">
                                <h2>PEOPLE ARE DIFFERENT</h2>
                                <h3>BECOME YOUR OWN BODY'S CHIEF SCIENTIST</h3>
                                <div className="narrative">
                                    <p>
                                        If there was a one-size-fits-all
                                        solution to chronic illness, it would
                                        have been found. Commonality.me is here
                                        to help people who have a label, but
                                        don't have a solution.
                                    </p>
                                    <p>
                                        What you need is to discover for
                                        yourself personalised interventions -
                                        and that’s what Commonality.me offers.
                                    </p>
                                    <p>
                                        Our 60 day program is called <strong>C60</strong>.
                                    </p>
                                </div>
                                <div style={{ marginTop: 40, marginBottom: 40 }} >
                                    <Button href="/landing">LEARN ABOUT C60</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div id="section3" className="section section3">
                    <Row>
                        <Col xs={{ span: 20, offset: 3  }} lg={{ span: 12, offset: 2 }}>
                            <div className="content">
                                <div className="separator" />
                                <h2>IS LIFE PASSING YOU BY?</h2>
                                <h3>TIME TO TAKE ACTION.</h3>
                                <div className="narrative">
                                    <p>
                                        It's not fair. That's what we think: if
                                        life is passing you by, it's not fair.
                                    </p>
                                    <p>
                                        But we also understand that some days
                                        are just really tough. We want to help
                                        you move to taking regular action - and
                                        body hack! You're not alone.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 2 }}>
                            <div className="form-submission">
                                <Submission />
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col xs={{ span: 20, offset: 2 }} lg={{ span: 12, offset: 6 }}>
                    <div className="separator" />
                                <h2>
                                    REAL PEOPLE* WITH REAL STORIES.
                                </h2>
                                <h3 style={{ marginTop: 40 }}>(*but people who don't want the whole internet knowing their current struggles with chronic illness)</h3>
                    </Col>
                </Row>
                
                <Row justify="center" style={{ marginTop: 100, marginBottom: 100 }}>
                    <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6, offset: 6 }}>
                    {/* <img src="https://picsum.photos/200">   */}
                    <img 
                        src="https://source.unsplash.com/QDPFWFCHes4/350x350"
                        alt="just a random image"
                    />
                    </Col>
                    <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 2 }}>
                    
                    <p className="quote">My name is X Y. I’ve lived with Chronic Fatigue Syndrome for almost 20 years.</p> 
                    
                    <p className="quote">I’ve seen all the doctors. Had all the tests. Taken all the drugs. </p>
                    <p className="quote">I was willing to test drive <a href='/landing'>Commonality's C60</a> because its approach is unique. </p>
                    <p className="quote">We’ve just hit the 60 day mark, and I wanted to say thanks to Chris, and give C60 a well-earned plug.</p>
                    <p className="quote">Chris, you're clearly a smart guy, and are bringing some heavy duty weapons to the fight to understand my health better. I think your <a href='https://en.wikipedia.org/wiki/Eating_your_own_dog_food' >dogfooding</a> of the collect-data-and-apply-machine-learning has sharpened the tools.</p> 
                    <p className="quote">Just knowing you've walked this path is encouraging to those of us still trying to find a way forward.</p>
                    <p className="quote">Whatever the outcome, the tiny bit of discipline required to record my data has been worth it.</p>                    
                    </Col>
                    
                </Row>

                <hr ref={this.separator} />
                <hr ref={this.separator} />
                <hr ref={this.separator} />
                <Seo facebook={facebook} />
                <style jsx>{`
                    p.quote {
                        margin-bottom: 25px;
                      }

                    hr {
                        margin: 0;
                        border: 0;
                    }
                    .section2-img-bg {
                        width: 100%;
                        height: 804px;
                        background-image: url(${section2ImgBg});
                        background-size: cover;
                        color: #000;
                        display: flex;
                        flex-flow: column nowrap;
                        justify-content: center;
                    }

                    #section3 {
                        background-image: url(${sectionImgBg});
                        background-size: cover;
                        height: 804px;
                    }

                    .section h3 {
                        margin: 0 0 60px;
                    }
                    .section h2 {
                        margin: 0 0 6px;
                    }

                    .separator {
                        display: block;
                        width: 42px;
                        height: 6px;
                        box-shadow: inset 0 4px 0 0 #cacaca;
                        margin: 114px auto 24px;
                    }

                    .section1 {
                        min-height: 702px;
                        position: relative;
                        text-align: center;
                    }

                    .section1 h2 {
                        margin: 0 0 96px;
                    }

                    .narrative {
                        margin-bottom: 0;
                        text-align: left;
                        color: #1b1b1b;
                        opacity: 0.8;
                    }

                    .narrative p {
                        line-height: 24px;
                        margin: 0;
                    }

                    .narrative p + p {
                        margin-top: 24px;
                    }

                    .section2 {
                        background-color: #1b1b1b;
                    }
                    .section2,
                    .section2 .narrative,
                    .section3,
                    .section3 .narrative {
                        color: #e3e7eb;
                    }

                    .section2 .separator {
                        margin: 102px 6px 12px 0;
                        position: relative;
                        left: 1px;
                    }

                    .section2 .section2-content {
                        padding: 15px;
                    }

                    .section3 .separator {
                        margin: 108px 6px 12px 0;
                        position: relative;
                        left: 1px;
                    }
                    .section3 {
                    }
                `}</style>
                <style jsx>{`
                    .testimonies .bar {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }

                    .testimonies .bar:before {
                        display: block;
                        content: "";
                        width: 500%;
                        height: 1000px;
                        position: absolute;
                        transform: translatex(-15px) rotate(13.49573deg)
                            translatey(-7px);
                        transform-origin: 0 0;
                        background: #f8f8f8;
                    }

                    .testimonies .testimony-image1 {
                        background-image: url(${testimonyPersonImg});
                        filter: saturate(80%);
                        -webkit-filter: saturate(80%);
                        -moz-filter: saturate(80%);
                        -o-filter: saturate(80%);
                        -ms-filter: saturate(80%);
                        width: 200px;
                        background-position: 65% 0;
                        background-size: auto;
                    }
                    .qoutes {
                        padding-left: 24px;
                    }
                    .qoutes .qoute-header {
                        font-size: 65px;
                        font-weight: bolder;
                        padding-top: 5px;
                        color: #f28500;
                        position: relative;
                        left: -6px;
                    }
                    .qoutes .qoute-body {
                        color: rgba(0, 0, 0, 0.7);
                        margin-bottom: 24px;
                    }

                    .qoutes .attribution {
                        color: rgba(27, 27, 27, 0.8);
                    }

                    .qoutes .attribution div {
                        font-size: 16px;
                        chrome-offset: 16px;
                        top: 2px;
                        letter-spacing: 0.2px;
                        position: relative;
                        line-height: 24px;
                    }
                    .testimony-1 {
                        margin-top: 132px;
                    }
                    .testimonies {
                        margin-bottom: 132px;
                    }

                    .testimony-image {
                        position: absolute;
                        left: 0;
                        background-repeat: no-repeat;
                        height: 252px;
                        top: 0;
                    }

                    .testimonies .qoute-large {
                        font-size: 90px;
                    }
                    .testimony {
                        min-height: 252px;
                        overflow: hidden;
                        border-radius: 4px;
                        background-color: #f8f8f8;
                        box-shadow: 0 40px 65px -24px rgba(0, 0, 0, 0.25);
                        transition: 1s;
                        position: relative;
                    }
                `}</style>
                <style jsx>{`
                    .section5 {
                        height: 700px;
                        width: 100%;
                        background-image: url(${section5ImgBg});
                    }
                `}</style>
            </React.Fragment>
        );
    }
}

IndexPage.propTypes = {
    data : PropTypes.object.isRequired
};

export default IndexPage;

//eslint-disable-next-line no-undef
export const query = graphql`
    query IndexQuery {
        posts: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
            sort: { fields: [fields___prefix], order: DESC }
        ) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                        prefix
                    }
                    frontmatter {
                        title
                        slug
                        category
                        author
                        cover {
                            children {
                                ... on ImageSharp {
                                    fluid(maxWidth: 800, maxHeight: 360) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                facebook {
                    appId
                }
            }
        }
        sectionImage: imageSharp(
            fluid: { originalName: { regex: "/section-background/" } }
        ) {
            resize(width: 1200, height: 1100, quality: 90, cropFocus: CENTER) {
                src
            }
        }
        section2Image: imageSharp(
            fluid: { originalName: { regex: "/section2-background/" } }
        ) {
            resize(width: 1200, height: 1100, quality: 90, cropFocus: CENTER) {
                src
            }
        }
        section5Image: imageSharp(
            fluid: { originalName: { regex: "/profile-app/" } }
        ) {
            resize(width: 645, height: 700, quality: 90, cropFocus: CENTER) {
                src
            }
        }
        bgDesktop: imageSharp(
            fluid: { originalName: { regex: "/hero-background/" } }
        ) {
            resize(width: 1200, quality: 90, cropFocus: CENTER) {
                src
            }
        }
        bgTablet: imageSharp(
            fluid: { originalName: { regex: "/hero-background/" } }
        ) {
            resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
                src
            }
        }
        bgMobile: imageSharp(
            fluid: { originalName: { regex: "/hero-background/" } }
        ) {
            resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
                src
            }
        }
        image1: imageSharp(fluid: { originalName: { regex: "/image1/" } }) {
            resize(width: 200, height: 252, quality: 90, cropFocus: CENTER) {
                src
            }
        }
    }
`;

//hero-background
